<template>
  <div>
    <template v-for="(correct, iBody) in exercise.body.correct">
      <div :key="iBody">
        <div style="display: flex" class="ml-12">
          <h4 class="mt-8 mr-4">
            <template v-if="type_expand == 0">
              {{
                iBody + section.offset_question + exercise.offset_question + 1
              }}.
            </template>
            <template v-if="type_expand == 1"> {{ iBody + 1 }}. </template>
          </h4>
          <h5 style="line-height: 3; color: #6495ed" class="mt-4">
            {{ iBody | getAlphabetCharacterFromIndex }}.
          </h5>
          <v-col cols="5" class="pb-0">
            <v-select
              readonly
              class="pt-0"
              :items="exercise.body.option"
              label="Option"
              required
              v-model="correct.index"
            ></v-select>
          </v-col>
          <p style="font-size: medium; line-height: 3" class="pt-2">
            - {{ correct.value }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "MatchingType",
  props: ["section", "exercise", "type_expand"],
};
</script>

<style></style>
